<template>
    <div class="">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="caz-blocks-sarcho-title">
                <!-- <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'"> {{ $t("message.social") }} </div> -->
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- end ifon-add-title-block -->
  
        <div class="card-table-header table-crm-smart">
            <!-- Instagram Posts Test -->

            <!-- <iframe src="http://localhost:8080/customForm/4343aac5-2750-41f1-82f9-0643b72b35c1/VTJGc2RHVmtYMTloOCtlOEFhZUxNS291dGdaSHpwUnVVVExBcEorMTBaVT0=?source_id=1" width="470" height="500" frameborder="0">
            </iframe> -->
            <button id="gocrmWidgetButton" type="button">Total</button>
            <!-- <el-button @click="loginInstagram()" type="primary">Login</el-button> -->
            
        </div>
      </div>
    </div>
</template>
  
<script>
  // @ is an alias to /src
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: "Social",
        components: {
        },
        mounted() {
          
          this.loadScript();
          // const script = document.createElement("script");
          // script.async = true;
          // script.defer = true;
          // script.crossorigin = "anonymous";
          // script.src = "https://connect.facebook.net/en_US/sdk.js";

          // document.body.appendChild(script);

          // window.fbAsyncInit = function() {
          //   FB.init({
          //     appId            : '580101920957590',
          //     autoLogAppEvents : true,
          //     xfbml            : true,
          //     version          : 'v17.0'
          //   });
          // };
        },

        data() {
            return {

            };
        },

        computed: {
            ...mapGetters({
                list: "sources/list",
                mode: "MODE"
            }),
            actions: function () {
                return ["edit", "delete"];
            },
        },
        methods: {
            ...mapActions({
                login: "instagram/loginInstagram",
            }),
            loadScript(){
              const myScript = document.createElement('script');
              const source = 'http://localhost:8080//js/gocrm_widget.js?form_url=http%3A%2F%2Flocalhost%3A8080%2FcustomForm%2F4343aac5-2750-41f1-82f9-0643b72b35c1%2FVTJGc2RHVmtYMTlKdE9xdk5ubG9MeVFZNE1rMFZJeko1cjRFOFdvalkvVT0%3D';
              myScript.src = source;
              document.body.appendChild(myScript);
            },


            loginInstagram(){
              // this.login();
              window.open(
                'https://7556-84-54-120-118.ngrok-free.app/api/login/facebook',
                '_blank' // <- This is what makes it open in a new window.
              );
            }
        
        },
    };
</script>
  
  